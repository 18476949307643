<template>
  <div class="type-free-shipping">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :flashsale-icon-style="{ '--bg': '#198055' }"
      :config="config"
    >
      <template slot="header">
        <FreeShippingHead
          :full-screen="isFullScreen"
          :promotion="promotion"
        />
      </template>
      <template #footer>
        <FreeShippingFoot
          :promotion="promotion"
          :add-item-type="promotion.addItemType"
          :thresholds="progressList"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { EventViewCart  } from '../../utils/event.js'
import AddOnItem from '../AddOnItem.vue'
import FreeShippingHead from './Head.vue'
import FreeShippingFoot from './Foot.vue'

export default {
  name: 'TypeFreeShipping',
  components: {
    AddOnItem,
    FreeShippingHead,
    FreeShippingFoot,
  },
  props: {
    promotion: { // 免邮信息，里面的字段和促销活动有差异
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowProgressUpAnimation: false, // 是否展示过进度提升气派
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'isFullScreen', 'progressDataPromise']),
    ...mapGetters(['jsonRuleId']),
    anotherInfo() {
      return {
        goodsPrice: ((this.promotion?.diffPrice?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        excludeTspIds: this.promotion?.excludeTspIds,
        includeTspId: this.promotion?.includeTspIds,
        jsonRuleId: this.jsonRuleId,
        addOnType: this.promotion?.addOnType,
      }
    },
    progressPercent() {
      return this.promotion?.progressPercent || 0
    },
    progressList() {
      return [{ 
        progressPercent: this.progressPercent,
        transition: { goForward: this.isActivityChange },
      }]
    },
    isActivityChange() {
      return this.promotion?.activityChange == '1'
    }
  },
  watch: {
    'progressPercent': {
      handler(newV, oldV) {
        if (newV > oldV || this.isActivityChange) {
          // 更新进度和气泡
          this.progressDataPromise?.resolve?.({
            bubbleText: this.getBubbleText(),
          })
        }
      },
      deep: true,
    }
  },
  methods: {
    getSaExtraData() {
      const extraData = {
        promotion_code: this.promotion?.biData?.promotion_code,
        coupon_change: this.promotion?.activityChange,
        is_satisfied: this.promotion?.biData?.is_satisfied,
      }
      return extraData
    },
    getBubbleText() {
      if (this.isShowProgressUpAnimation) return ''
      this.isShowProgressUpAnimation = true
      return this.promotion?.bubbleTip
    },
    viewCart(data) {
      EventViewCart.notify(data)
    },
  },
}
</script>
<style lang="less" scoped>
.type-free-shipping {
  height: 100%;
}
</style>
