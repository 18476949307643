<template>
  <div class="type-promotion">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="saInfo"
      :config="config"
      :footer-type="footerType"
      :feeds-type="feedsType"
      :get-business-cart-foot="getBusinessCartFoot"
    >
      <template slot="header">
        <HeadAccessory 
          v-if="typeStatus.isAccessory"
          ref="HeadAccessory"
          :promotion="promotion"
          :type-status="typeStatus"
          @pick="handlePick"
        />
        <PromotionHead
          v-else
          :promotion="promotion"
          :type-status="typeStatus"
        />
      </template>
      <template #footer>
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="promotion"
          scene="promotion"
          :promotion-id="promotion.promotion_id"
          :state="saInfo.state"
          :tips="popupInfo.progressTip"
          :status="addOnStatus"
          :abt-type="promotionBottomStyle"
          :auto-use-coupon="autoUseCoupon"
          :auto-use-point="autoUsePoint"
          :feeds-type="feedsType"
          :theme="typeStatus.isAccessory ? '' : 'promotion'"
          :theme-vars="themeVars"
          :add-item-type="addItemType"
          :config="$attrs.config"
          @checkoutClick="viewCart"
          @cartUpdated="hanldeBusinessCartUpdated"
          @re-pick="handlePick"
        >
          <template #progress>
            <ProgressBar
              transparent
              animation
              :thresholds="progressList"
            />
          </template>
        </BusinessCartFoot>
        <CommonFoot
          v-else
          :add-item-type="addItemType"
          :progress-tip="popupInfo.progressTip"
          :btn-text="popupInfo.goToCheckoutTip"
          :thresholds="progressList"
          :equity-tip="popupInfo.equityTip"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getRangeStatus, getTypeStatus } from 'public/src/pages/common/add-on/utils/index.js'
import { EventUpdateCart, EventSetProgressPromise, EventPickItem, EventViewCart  } from '../../utils/event.js'
import AddOnItem from '../AddOnItem.vue'
import PromotionHead from './Head/index.vue'
import HeadAccessory from './HeadAccessory/index.vue'
import CommonFoot from './CommonFoot.vue'
import BusinessCartFoot from 'public/src/pages/common/add-on/comps/business-cart-foot/index.vue'
import ProgressBar from 'public/src/pages/common/add-on/comps/base/ProgressBar.vue'

export default {
  name: 'TypePromotion',
  components: {
    AddOnItem,
    PromotionHead,
    HeadAccessory,
    CommonFoot,
    BusinessCartFoot,
    ProgressBar,
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowRangeUpAnimation: false, // 是否展示过升档气泡
      isShowProgressUpAnimation: false, // 是否展示过进度提升气派
      couponChange: '0',

      isShowAutoPick: false, // 是否已经自动弹出过
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'progressDataPromise']),
    ...mapGetters(['promotionBottomStyle', 'feedsType', 'autoUseCoupon', 'autoUsePoint', 'jsonRuleId']),
    addItemType() {
      return this.popupInfo?.addItemType
    },
    typeStatus() {
      return getTypeStatus(this.promotion.type_id, this.promotion.promotion_logo_type)
    },
    saInfo () {
      return this.$attrs?.saInfo || {}
    },
    popupInfo() {
      return this.promotion?.promotionPopupInfo || {}
    },
    anotherInfo() {
      return {
        goodsPrice: ((this.promotion?.diffMoney?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: this.popupInfo?.includeTspIds,
        jsonRuleId: this.jsonRuleId
      }
    },
    isBusinessCartBottom() {
      return ['A', 'B', 'C'].includes(this.promotionBottomStyle) && !this.config?.isCloseBusinessCartEntry
    },
    footerType() {
      if (this.isBusinessCartBottom) return 'business-cart'
      return 'normal'
    },
    progressList() {
      if (this.popupInfo.thresholds) return this.popupInfo.thresholds
      return [{ progressPercent: this.popupInfo?.progressPercent || 0 }]
    },
    addOnStatus() { // 凑单状态: 0 - 全部未满足; 1 - 全部满足; 2 - 部分满足
      const { range, isMeetMaxLimit, infiniteGrade, thresholds, progressPercent } = this.popupInfo
      if (!thresholds?.length) return '-'
      if (infiniteGrade == '1') {
        return range > 0 ? (isMeetMaxLimit ? '1' : '2') : '0'
      }
      if (!thresholds) return progressPercent == 1 ? '1' : '0'
      return range > 0 ? (range == thresholds.length ? '1' : '2') : '0'
    },
    // 非标车
    themeVars() {
      return this.isNewUser ? {
        '--cart-title-color': '#FFFDD8',
        '--cart-close-color': '#fff', // 关闭按钮颜色
        '--cart-delete-title-color': '#fff', // 删除挽留弹窗主标题颜色
        '--cart-delete-close-color': '#fff', // 删除挽留弹窗关闭 "x", icon 颜色
        '--cart-title-doubt-color': '#fff', // 标题问号颜色
        '--cart-promo-header-bg': 'linear-gradient(183deg, #FFD0D0 2.79%, #FFF 59.78%), linear-gradient(183deg, #FFF4CD 2.79%, #FFF 59.78%)', // 活动分组背景
      } : {}
    },
    isNewUser() {
      return this.popupInfo.style == '1'
    },
  },
  watch: {
    'progressList': {
      handler(newV, oldV) {
        const { isUp, isChange, isRangeUp } = getRangeStatus(newV, oldV)
        if (isChange) {
          this.$refs.BusinessCartFoot?.refetchCarts()
          // 更新进度和气泡
          this.progressDataPromise?.resolve?.({
            bubbleText: this.getBubbleText({ isRangeUp, isUp }),
          })
        }
      },
      deep: true,
    },
    'popupInfo.range'(newRange, oldRange) {
      if (this.typeStatus.isAccessory && !this.isShowAutoPick && newRange > 0 && oldRange == 0) {
        this.isShowAutoPick = true
        this.handlePick({ isAuto: true })
      }
    },
  },
  methods: {
    getSaExtraData() {
      const extraData = {
        promotion_code: this.promotion?.promotion_id,
        coupon_change: this.couponChange,
        is_satisfied: this.addOnStatus,
        newuser_label: this.popupInfo.biData?.isNewUserReturnCoupon,
      }
      if (this.promotion.coupon_num) {
        extraData.is_couponbag = this.promotion.coupon_num > 1 ? '1' : '0'
      }
      if (this.typeStatus.isAccessory) {
        extraData.action = this.popupInfo.range != 0 ? 'pick' : 'view'
      }
      return extraData
    },
    getBubbleText({ isRangeUp, isUp }) {
      if (!isUp) return '' // 无提升,不谈出气泡
      if (this.isShowRangeUpAnimation) return '' // 展示过升档气泡就不再弹气泡了

      if (isRangeUp) {
        this.couponChange = '1'
        this.isShowRangeUpAnimation = true
        return this.popupInfo.upgradingBubbleTip
      }

      if (this.isShowProgressUpAnimation) return ''
      this.isShowProgressUpAnimation = true
      return this.popupInfo?.bubbleTip
    },
    getBusinessCartFoot() {
      return this.$refs.BusinessCartFoot
    },
    handlePick(info) {
      EventPickItem.notify({ 
        promotionId: this.promotion?.promotion_id,
        fromType: info?.isAuto ? 'addItem-auto' : 'addItem',
      })
    },
    hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
        EventUpdateCart.notify()
      }
    },
    viewCart(data) {
      if (this.$refs.HeadAccessory?.needRetain) {
        this.$refs.HeadAccessory.toggleDrawer?.(true)
        return
      }
      EventViewCart.notify(data)
    },
  },
}
</script>
<style lang="less" scoped>
.type-promotion {
  height: 100%;
}
</style>
