var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"free-shipping-header",class:{
    'free-shipping-header_full-screen': _vm.fullScreen,
  }},[_c('CloseIcon',{staticClass:"free-shipping-header__close",class:{
      'free-shipping-header__close_full-screen': _vm.fullScreen,
    },attrs:{"size":"16","color":"#fff"}}),_vm._v(" "),_c('section',{staticClass:"free-shipping-header__content"},[_c('div',{staticClass:"free-shipping-header__tips",class:{
        'free-shipping-header__tips_full-screen': _vm.fullScreen,
      },domProps:{"innerHTML":_vm._s(_vm.promotion.topTitle)}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.timeStamp > 0 && !_vm.fullScreen),expression:"timeStamp > 0 && !fullScreen"}],staticClass:"free-shipping-header__countdown"},[_c('span',{staticClass:"countdown__label"},[_vm._v(_vm._s(_vm.promotion.endTip))]),_vm._v(" "),_c('CountDown',{attrs:{"time-stamp":_vm.timeStamp}})],1)]),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fullScreen),expression:"!fullScreen"}],staticClass:"free-shipping-header__img",attrs:{"src":_vm.headerCartImg}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }