<template>
  <div class="add-on-container j-add-on-container">
    <div
      v-if="visible"
      class="add-on-container__shade"
    >
    </div>
    <template v-if="errorComponent">
      <NoNetworkEmpty
        v-show="errorComponent == 'ErrorNetwork'"
        :is-empty="true"
        :language="language"
        @online-changed="handleOnlineStatus"
      />
      <component
        :is="errorComponent"
        v-show="isOnline"
        v-bind="{
          visible,
          language,
          statusMsg,
        }"
        @close="handleClose"
      />
    </template>
    <s-drawer
      v-else
      ref="add-on-container__drawer"
      class="container-drawer"
      :class="{
        'half-radius': !isFullScreen,
      }"
      :visible="visible"
      v-bind="drawerCurrentConfig"
      @update:visible="handleClose()"
      @closed="resetAndClose"
    >
      <div
        v-if="visible"
        class="container-drawer__float"
      >
        <div ref="addOnFloatWrapper"></div>
      </div>
      <component
        :is="componentNameFromType"
        :ref="componentNameFromType"
        :drawer-config="drawerConfig"
        v-bind="bindProps"
      />
      <NoNetworkEmpty
        :is-empty="!bindProps.type"
        :language="language"
        @online-changed="handleOnlineStatus"
      />
    </s-drawer>
  </div>
</template>

<script>
import TypeCoupon from 'public/src/pages/common/add-on/layout/typeCoupon/index.vue'
import TypePromotion from 'public/src/pages/common/add-on/layout/typePromotion/index.vue'
import TypeFreeShipping from 'public/src/pages/common/add-on/layout/typeFreeShipping/index.vue'
import ErrorDisable from 'public/src/pages/common/add-on/layout/ErrorDisable.vue'
import ErrorNetwork from 'public/src/pages/common/add-on/layout/ErrorNetwork.vue'
import NoNetworkEmpty from 'public/src/pages/cart_v2/components/offline/NoNetworkEmpty.vue'
import { fetchAddOnABTLanguageCardConfig } from 'public/src/pages/common/add-on/utils/fetcher.js'
import { HOOKS, COUPON_DISABLE_CODES, ADD_ON_COMP_MAP } from 'public/src/pages/common/add-on/utils/constants.js'
import { 
  EventExposeAddCart, 
  EventUpdateCart, 
  EventViewCart, 
  EventFetchError, 
  EventClose,
  EventPickItem,
  EventUpdateBusinessCart,
  EventViewTap,
  removeAllEvents,
} from 'public/src/pages/common/add-on/utils/event.js'
import { setCouponDataForAddOnItem  } from 'public/src/pages/common/add-on/utils/couponData.js'
import { isFunction } from '@shein/common-function'
import apiReport from 'public/src/pages/common/apiReport/index.js'
import { mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { routerPush } from './utils/index'
daEventCenter.addSubscriber({ modulecode: '1-8-4' })
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
const { langPath } = gbCommonInfo
export default {
  name: 'AddOnNew',
  components: {
    TypeCoupon,
    TypePromotion,
    TypeFreeShipping,
    ErrorDisable,
    ErrorNetwork,
    NoNetworkEmpty,
  },
  data() {
    return {
      bindProps: {},
      hooks: {}, // 钩子函数
      visible: false,
      language: {}, // 多语言

      statusCode: 0, // 默认为0，正常情况
      statusMsg: '',
      addCartNum: 0, // 加车总值

      isOnline: true,
    }
  },
  computed: {
    ...mapState(['isFullScreen', 'addSize']),
    drawerConfig() {
      return {
        size: '90%',
        maxSize: '90%',
        ...this.bindProps?.drawerConfig,
        'no-header': this.isFullScreen,
        modal: false,
      }
    },
    drawerCurrentConfig() {
      const { size, maxSize } = this.drawerConfig
      return {
        ...this.drawerConfig,
        size: `${parseFloat(size) + (this.addSize || 0)}%`,
        maxSize: `${parseFloat(maxSize) + (this.addSize || 0)}%`,
      }
    },
    componentNameFromType() {
      return ADD_ON_COMP_MAP[this.bindProps?.type]
    },
    errorComponent() {
      if (this.statusCode == 0) return ''
      return COUPON_DISABLE_CODES.includes(+this.statusCode) ? 'ErrorDisable' : 'ErrorNetwork'
    }
  },
  methods: {
    ...mapMutations([
      'setAbtInfo',
      'setCardConfig',
      'setLanguage',
      'resetState',
    ]),
    async open(config) {
      this.executeHook(HOOKS.beforeOpen)
      this.visible = true
      document.body.classList.add('add-on_hidden-to-body')
      await this.fetchAbt()
      this.initConfig(config)
      this.initEvent()
      apiReport.report({
        apiPath: 'add_popup/bi_parameter_empty',
        parameter_detail: this.bindProps,
      })
      this.executeHook(HOOKS.open, {
        topFloatEl: this.$refs.addOnFloatWrapper
      })

      // 监听路由变化关闭凑单
      if (window._gb_app_?.$route) {
        const unwatch = this.$watch(() => window._gb_app_.$route, () => {
          this.$nextTick(() => {
            unwatch()
          })
          this.handleClose()
        })
      }
    },
    resetAndClose() {
      document.body.classList.remove('add-on_hidden-to-body')
      Object.assign(this.$data, this.$options.data()) // 重置组件状态
      this.resetState() // 重置 store
      // 移除所有eventbus订阅
      removeAllEvents()
    },
    initConfig(config) {
      const { props, on } = config
      this.bindProps = props || {}
      this.hooks = on || {}
    },
    updatePromotion(payload) {
      this.bindProps.promotion = payload || {}
    },
    async fetchAbt() {
      const [abt, config, languageInfo] = await (window.preFetchAddOnABTLanguageCardConfig || fetchAddOnABTLanguageCardConfig())
      // eslint-disable-next-line require-atomic-updates
      window.preFetchAddOnABTLanguageCardConfig = null
      if (config?.info.fields) {
        config.info.fields.cccFeedback = abt.RecommendExpandPop?.p === 'pop'
      }
      this.setAbtInfo(abt)
      this.setCardConfig(config?.info)
      if(Object.keys(this.language).length == 0) {
        this.language = languageInfo.language
        this.setLanguage(languageInfo.language)
      }
    },

    handleClose(info) {
      const ref = this.$refs[this.componentNameFromType]
      const saData = ref?.getSaExtraData?.()
      daEventCenter.triggerNotice({
        daId: '1-8-4-12',
        extraData: {
          state: this.bindProps?.saInfo?.state,
          add_cart_number: this.calcAddCartNumber(this.addCartNum, saData),
          ...(saData || {}),
        }
      })

      this.executeHook(HOOKS.close, info)

      // this.resetAndClose()
      this.visible = false
    },

    // 多处使用统一监听注册
    initEvent() {
      // 凑单弹窗曝光事件
      EventExposeAddCart.subscribe({
        callback: ({ service_error = '' } = {}) => {
          const saData = this.$refs[this.componentNameFromType]?.getSaExtraData?.()
          daEventCenter.triggerNotice({
            daId: '1-8-4-13',
            extraData: {
              state: this.bindProps?.saInfo?.state,
              parameter_detail: this.bindProps,
              promotion_code: service_error ? '-' : saData?.promotion_code,
              is_satisfied: saData?.is_satisfied,
              service_error,
              is_multiple_coupons: saData?.is_multiple_coupons || '-',
              newuser_label: saData?.newuser_label || 0,
            }
          })
        }
      })
      // 内部加车事件
      EventUpdateCart.subscribe({
        callback: (cartInfo) => {
          this.addCartNum += 1
          this.executeHook(HOOKS.updateCart, cartInfo)
        }
      })
      // 点击viewCart
      EventViewCart.subscribe({
        callback: (data) => {
          const saData = this.$refs[this.componentNameFromType]?.getSaExtraData?.()
          daEventCenter.triggerNotice({
            daId: '1-8-4-10',
            extraData: {
              state: this.bindProps?.saInfo?.state,
              add_cart_number: this.calcAddCartNumber(this.addCartNum, saData),
              ...(saData || {}),
            }
          })

          this.executeHook(HOOKS.viewCart, data)

          if (this.bindProps?.config?.continueBtnToClose || window?.SaPageInfo?.page_name == 'page_cart') {
            this.resetAndClose()
            return
          }

          // 非购物车页点击底部ViewCart按钮
          setCouponDataForAddOnItem(data)
          const cartPath = `${langPath}/cart`
          routerPush(cartPath, 'router')
        }
      })
      // 组件异常情况
      EventFetchError.subscribe({
        callback: ({ code, msg }) => {
          if (code == 0) return
          this.statusMsg = msg
          this.statusCode = code
        }
      })

      EventClose.subscribe({
        callback: (payload) => {
          this.handleClose(payload)
        }
      })

      EventPickItem.subscribe({
        callback: (payload) => {
          const saData = this.$refs[this.componentNameFromType]?.getSaExtraData?.()
          daEventCenter.triggerNotice({
            daId: '1-8-4-16',
            extraData: {
              add_cart_number: this.calcAddCartNumber(this.addCartNum, saData),
              coupon_change: saData?.coupon_change,
              action: saData?.action,
            }
          })

          this.executeHook(HOOKS.pickItem, payload)
        }
      })
      EventUpdateBusinessCart.subscribe({
        callback: () => {
          this.executeHook(HOOKS.updateCart)
        }
      })
      EventViewTap.subscribe({
        callback: (payload) => {
          this.executeHook(HOOKS.viewTap, payload)
        }
      })
    },

    // 外部钩子
    executeHook(name, info) {
      // 校验接入方入参
      if (!isFunction(this.hooks?.[name])) return
      this.hooks[name](info)
    },

    calcAddCartNumber(addCartNum, saData) {
      const other_add_cart_number = saData?.other_add_cart_number || 0
      let add_cart_number = addCartNum
      // 如果存在 other_add_cart_number, 则内部组件统计了其他加车数量, add_cart_number 需要减去 other_add_cart_number
      if (other_add_cart_number) {
        add_cart_number -= other_add_cart_number
      }
      return add_cart_number
    },

    handleOnlineStatus(val) {
      this.isOnline = val
    },
    // 更新props
    updateOpts(props = {}) {
      this.bindProps = Object.assign({}, this.bindProps, props)
    }
  },
}

</script>

<style lang="less" scoped>
.add-on-container {
  &__shade {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: 1000; /* stylelint-disable-line declaration-property-value-blacklist */
    transform: translate3d(0px, 0px, 1000px);
  }
  /deep/.S-drawer__container_btt {
    border-radius: unset;
  }
}
.container-drawer {
  &.half-radius /deep/ .S-drawer__container {
    border-radius: 14px 14px 0 0;
  }
  /deep/ .S-drawer__container {
    overflow: visible;
  }

  /deep/ .S-drawer__header {
    height: 0;
    min-height: 0;
    border-bottom: none;
    z-index: 9;
  }
  /deep/ .S-drawer__body {
    height: 100%;
    background-color: transparent;
    overflow: visible;
  }
  .container-drawer__float {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    width: 100%;
    transform: translateY(-100%);
  }
}
</style>
<style lang="less">
/* stylelint-disable-next-line selector-class-pattern */
.add-on_hidden-to-body {
  overflow: hidden;
}
</style>
