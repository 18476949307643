// 凑单默认埋点
export const SA_INFO = {
  activity_from: '', // 来源
  state: '', // 场景值
  style: 'popup',
  poskey: 'addnewhotJson', // 用于请求abtest, 多个逗号隔开
}

// 凑单钩子函数
export const HOOKS = {
  beforeOpen: 'beforeOpen',
  open: 'open',
  close: 'close',
  viewCart: 'viewCart',
  updateCart: 'updateCart',
  pickItem: 'pickItem',
  viewTap: 'viewTap',
}

// 凑单券不可用状态
export const COUPON_DISABLE_CODES = [
  500110, 500125, 500123, 500161
]

// 凑单类型对应组件
export const ADD_ON_COMP_MAP = {
  'coupon': 'TypeCoupon',
  'promotion': 'TypePromotion',
  'freeShipping': 'TypeFreeShipping',
}

export const LabelKeyMap = {
  STORE: 'store'
}
