<template>
  <s-drawer
    :visible="visible"
    class="container-drawer"
    size="65%"
    max-size="65%"
    :close-font-size="30"
    :show-close="true"
    @update:visible="$emit('close')"
  >
    <div slot="top"></div>
    <div class="error-network">
      <img 
        class="image" 
        :src="imgSrc"
      />
      <p class="tips">
        {{ statusMsg }}
      </p>
    </div>
  </s-drawer>
</template>

<script>
import { EventExposeAddCart } from 'public/src/pages/common/add-on/utils/event.js'
const { PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'ErrorNetwork',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({})
    },
    statusMsg: {
      type: String,
      default: ''
    },
  },
  computed: {
    imgSrc() {
      return `${PUBLIC_CDN}/pwa_dist/images/add-on/sui_image_fuse-9e74522eb0.png`
    }
  },
  mounted() {
    EventExposeAddCart.notify({
      service_error: 'promotion_unknown_error',
    })
  },
} 
</script>

<style lang="less" scoped>
.container-drawer {
  /deep/ .S-drawer__container {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  /deep/ .S-drawer__header {
    border-bottom: none;
    min-height: unset;
    z-index: @zindex-header;
  }
  /deep/ .S-drawer__body {
    height: 100%;
  }
  .error-network {
    padding: 180/75rem 24/75rem 24/75rem;
    text-align: center;
    .image {
      width: 150/75rem;
      height: 150/75rem;
    }
    .tips {
      margin-top: 24/75rem;
      color: #767676;
      font-size: 14px;
    }
  }
}
</style>
